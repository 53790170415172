@import '../lib/bootstrap/scss/bootstrap';
@import "./variables";


.section.section-tilt{
    --color-headline: var(--color-primary);
    --color-text: var(--color-black);

    color: var(--color-text);
    padding: var(--space-xxl) var(--space-lg);
    position: relative;
	@include media-breakpoint-down(md) {
		padding: var(--space-xxl) var(--space-xs)
	}
    &::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: skewY(-3deg);
        z-index: var(--layer-behind);
    }
    &.primary{
        --color-headline: var(--color-white);
        --color-text: var(--color-white);

        &::before{
            background-color: var(--color-primary)
		}

		// Duplication of the following is needed for IE Fallback
		color: var(--color-text);
		.section-title{
			color: var(--color-headline);
		}
		a, button{
            color: var(--color-text);
        }
    }
    &.accent{
        --color-headline: var(--color-white);
        --color-text: var(--color-white);

        &::before{
            background-color: var(--color-accent)
		}

		// Duplication of the following is needed for IE Fallback
		color: var(--color-text);
		.section-title{
			color: var(--color-headline);
		}
		a, button{
            color: var(--color-text);
        }
    }
    &.dark{
        --color-headline: var(--color-gray-dark);
        --color-text: var(--color-white);

        &::before{
            background-color: var(--color-gray-dark)
		}

		// Duplication of the following is needed for IE Fallback
		color: var(--color-text);
		.section-title{
			color: var(--color-headline);
		}
		a, button{
            color: var(--color-text);
        }
    }

    &.primary, &.accent {
        a, button{
            color: var(--color-text);
            text-decoration: underline;
        }
	}

    > .container{
        margin: 0 auto;
    }
    .section-title{
		color: var(--color-headline);
		margin-bottom: var(--space-md);
        &.center{
			text-align: center;
        }
    }
}

.dark.footer{
    background-color: var(--color-gray-dark);
    margin-top: var(--space-xxl);
    padding-top: calc(var(--space-xxl) / 2) !important;
    &:before{
        transform-origin: bottom left;
	}
	ul {
		margin-bottom: 12px !important;
		padding: 0;
		li {
			list-style-type: none;
		}
	}
}
