@import '../lib/bootstrap/scss/bootstrap';
@import "./variables";

.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-white-transparent);
    backdrop-filter: blur(5px);
    color: var(--color-black);
    box-shadow: var(--shadow-sm);
    z-index: var(--layer-header);
    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding: 0 5px;
        &:after{
            /* remove bootstrap after attribute from dom - breaks flex alignment*/
            display: none;
        }
				.header-alert-button {
					margin-top: .4rem;
				}
    }
    .logo{
        height: 2.75rem;
    }
    .nav{
        display: flex;
        align-items: center;
        .nav-link{
            color: inherit;
            border-radius: var(--radius-sm);
            padding: var(--space-xs);
            margin-left: var(--space-xxs);
            &:hover, &:focus{
                background-color: rgb(238, 238, 238);
            }
            &.active{
                font-weight: bold;
				color: var(--color-white);
				background-color: var(--color-accent)
            }
        }
    }
	ul.nav > li {
		margin-right: 8px;
	}
 	ul.nav > li > a {
		margin: 2px!important;
	}
	ul.nav > li.nav-link-login > a > .fa {
		margin-right: 8px!important;
	}
	.btn-primary-nuxt {
		display: inline-flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		text-decoration: none !important;
		font-size: 16px;
		font-weight: 700;
		font-family: "PT Sans Narrow", "PT Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
		line-height: 19.2px;
		min-width: 136px;
		min-height: 35.2px;
		color: var(--color-white);
		border: 1px solid var(--color-primary);
		padding: 8px 12px 6px 12px;
		background-color: var(--color-primary);
		border-color: var(--color-primary);
		border-radius: 0.25rem;
		transition-property: background, box-shadow;
		transition-duration: 0.3s;
		&:hover {
			color: var(--color-white);
			background-color: var(--color-primary-dark);
		}
	}
	.btn-primary-nuxt-outline {
		display: inline-flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		text-decoration: none !important;
		font-size: 16px;
		font-weight: 700;
		font-family: "PT Sans Narrow", "PT Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
		line-height: 19.2px;
		min-width: 136px;
		min-height: 35.2px;
		color: var(--color-primary);
		border: 1px solid var(--color-primary);
		padding: 8px 12px 6px 12px;
		background-color: transparent;
		border-color: var(--color-primary);
		border-radius: 0.25rem;
		transition-property: background, box-shadow;
		transition-duration: 0.3s;
		&:hover {
			border-color: var(--color-primary-dark);
			color: var(--color-primary-dark);
			box-shadow: 0 0 0 1px rgb(133, 5, 44);
		}
	}
    @include media-breakpoint-down(md) {
         .container{
            flex-wrap: wrap;
						&:not(:has(.alert-button)) {
							justify-content: center;
  					}
						&:has(.alert-button) {
							justify-content: space-between;
						}
        }


        .logo{
            height: 2.25rem;
						margin-left: 10px;
        }
        .nav{
             display: flex;
			flex-flow: row wrap;
			text-align: center;
			justify-content: center;
			white-space: nowrap;
			padding-top: 2px;
            > li {
                display: inline-block;
				margin-right: 0 !important;
            }
            .nav-link{
                margin: 0;
                margin-bottom: 4px;
            }
        }
    }
}


